/* Background
-------------------------------*/
body {
  background-color: #191820;
  color: var(--px-text);
  font-family: $px-font;
  --bs-body-font-weight: 400;
  --bs-body-font-size: 1rem;
  --bs-body-line-height: 1.6;
  overflow-x: hidden;
  /*background-image: linear-gradient(to right bottom, #571749, #451943, #35193b, #271731, #1b1426, #1b1528, #1b152b, #1b162d, #271a3e, #351d4f, #471e5f, #5b1c6d);*/
}

a {
  text-decoration: none;
}

.effect-section {
  position: relative;
  overflow: hidden;

  .effect-3 {
    position: absolute;
    left: 18%;
    top: 15%;
    width: 140px;

    img {
      animation: animo-x 8s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }

  .effect-4 {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    height: 450px;

    img {
      height: 100%;
    }
  }
}

/* ----------------------
*   Slider 
---------------------------*/
.full-width {
  width: 95vw;

  .slick-dots {
    max-width: 1300px;
  }
}

.slick-dots {
  text-align: center;
  font-size: 0px;
  padding-top: 35px;

  li {
    display: inline-block;
    vertical-align: top;
    width: 10px;
    height: 10px;
    background: transparent;
    border: 1px solid var(--px-white);
    transition: ease all 0.55s;
    border-radius: 8px;
    margin: 0 4px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    button {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      cursor: pointer;
      opacity: 0;
    }

    &.slick-active {
      background: var(--px-theme);
      width: 20px;
    }
  }
}

.owl-nav {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;

  .owl-next,
  .owl-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 35px;
    height: 35px;
    background: var(--px-theme);
    color: var(--px-theme-text);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    pointer-events: auto;
    transition: ease all 0.35s;

    &:hover {
      background: var(--px-theme-text);
      color: var(--px-theme);
    }
  }

  .owl-next {
    right: 0px;

    @include up-lg {
      right: -50px;
    }
  }

  .owl-prev {
    left: 0px;

    @include up-lg {
      left: -50px;
    }
  }
}

/* ----------------------
*   Home Banner
---------------------------*/
.home-section {
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-top: 110px;
  background-image: url('../../../public/images/bg-gradient-1.png');
  background-position: left center;
  background-repeat: no-repeat;

  @include down-md {
    padding-top: 130px;
  }

  .hs-text-box {
    @include down-md {
      padding-bottom: 50px;
    }

    h6 {
      margin: 0 0 10px;

      span {
        background: rgba(var(--px-theme-rgb), 0.2);
        border-radius: 4px;
        padding: 5px 12px;
        display: inline-block;
        color: var(--px-white);
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 4px;
        text-transform: uppercase;
      }
    }

    h1 {
      color: var(--px-white);
      font-weight: 700;
      font-size: 56px;
      text-transform: uppercase;

      @include down-lg {
        font-size: 45px;
      }

      @include down-sm {
        font-size: 38px;
      }

      span img {
        transform-origin: 70% 70%;
        animation: wave-animation 2.5s infinite !important;
        max-width: 12%;
      }
    }

    h2 {
      color: var(--px-theme);
      font-weight: 700;
      font-size: 50px;
      text-transform: uppercase;
      margin: 0 0 30px;

      @include down-lg {
        font-size: 45px;
        margin: 0 0 20px;
      }

      @include down-sm {
        font-size: 30px;
        margin: 0 0 10px;
      }
    }

    .text {
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      letter-spacing: 0.01em;
      color: var(--px-white);

      @include down-lg {
        font-size: 18px;
      }

      @include down-sm {
        font-size: 16px;
      }
    }

    .btn-bar {
      padding-top: 15px;
    }
  }
}

.opas {
  opacity: 0.6;
}

.opas:hover {
  opacity: 1;
  cursor: pointer;
}

.brand-bg {
  background: rgba(255, 255, 255, 0.05);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

/* ----------------------
*   About
---------------------------*/
.about-section {
  overflow: hidden;
  background-image: url('../../../public/images/bg-gradient-1_1.png');
  background-position: right center;
  background-repeat: no-repeat;

  .container {
    position: relative;
    z-index: 1;
  }

  .effect-1 {
    position: absolute;
    bottom: -50px;
    left: 0;
    z-index: -1;

    /* animation: rotate360 reverse 40s linear infinite;*/
    @include down-md {
      display: none;
    }
  }

  .effect-2 {
    position: absolute;
    top: 80px;
    right: -60px;
    z-index: -1;
    animation: rotate360 40s linear infinite;

    @include down-md {
      display: none;
    }
  }

  .about-text {
    .section-heading {
      margin-bottom: 25px;
    }

    p {
      color: var(--px-white);
    }
  }

  .review-box {
    display: flex;
    padding-top: 15px;
    padding-bottom: 45px;

    .r-box {
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.15);
      display: flex;
      flex-direction: column;
      color: var(--px-white);
      border-radius: 8px;
      padding: 20px;
      width: 100%;

      h3 {
        margin: 0 0 8px;
      }

      label {
        font-size: 13px;
        line-height: 130%;
      }

      +.r-box {
        margin-left: 10px;
      }
    }
  }
}

/* ----------------------
*   Projects
---------------------------*/
.project-section {
  overflow: hidden;
  background-image: url('../../../public/images/bg-gradient-2.png');
  background-position: left center;
  background-repeat: no-repeat;

  .owl-stage-outer {
    overflow: inherit;
  }
}

.project-box {
  padding: 14px;
  border-radius: 10px;
  background: linear-gradient(156.67deg, rgba(112, 112, 114, 0.8) 0.07%, rgba(100, 97, 113, 0.148) 99.07%);
  box-shadow: 0px 7px 40px rgba(5, 34, 52, 0.06);
  border-radius: 16px;
  box-sizing: border-box;
  border: 2px solid transparent;

  .project-media {
    position: relative;
    overflow: hidden;
    border-radius: 8px;

    img {
      transition: ease all 0.35s;
      transform: scale(1);
    }

    .gallery-link {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      cursor: pointer;
      transition: ease all 0.35s;

      i {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--px-white);
        color: var(--px-black);
        font-size: 35px;
        border-radius: 50%;
      }
    }
  }

  &:hover {
    .project-media {
      img {
        transform: scale(1.05);
      }

      .gallery-link {
        opacity: 1;
      }
    }
  }

  .project-body {
    display: flex;
    align-items: center;
    padding-top: 20px;

    h5 {
      font-weight: 700;
      color: var(--px-white);
      font-size: 18px;
      margin: 0 0 5px;
    }

    .text {
      flex: 0 0 calc(100% - 50px);
      padding-right: 10px;

      span {
        font-size: 14px;
        color: #ffffff8e;
      }
    }

    .link {
      width: 50px;

      .p-link {
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--px-theme);
        color: var(--px-white);
        font-size: 20px;
        border-radius: 50%;
        border: 2px solid var(--px-black);
        cursor: pointer;
        transition: all 0.4s ease;

        &:hover {
          background: var(--px-black);
        }
      }
    }
  }
}

/* ----------------------
*   Skills
---------------------------*/
.skills-section {
  overflow: hidden;
 

.skill-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(156.67deg, rgba(112, 112, 114, 0.4) 0.07%, rgba(100, 97, 113, 0.148) 99.07%);
  padding: 16px 15px 16px 17px;
  border-radius: 12px;
  margin: 10px 0;

  @include down-sm {
    padding: 10px;
  }

  .skill-img img {
    width: 75%;

    @include down-sm {
      width: 100%;
    }
  }

  .skill-text {
    @include down-sm {
      margin-left: 5px;
    }
  }

  .skill-text h3 {
    font-size: 1rem;
  }
}
}



/* ----------------------
*   Services
---------------------------*/
.services-box {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 150px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  &:after {
    content: '';
    background: linear-gradient(180deg, rgba(4, 12, 22, 0) 0%, #040c16 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .services-body {
    padding: 20px;
    position: relative;
    z-index: 1;

    .icon {
      width: 50px;
      height: 50px;
      background: var(--px-theme);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--px-white);
      font-size: 22px;
      border-radius: 5px;
      margin-bottom: 20px;
    }

    p {
      color: var(--px-white);
      font-size: 14px;
      line-height: 140%;
      opacity: 0.65;
      margin: 0 0 15px;
    }

    .rating-wrap {
      padding-top: 10px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

.ratings {
  position: relative;
  width: 102px;
  font-size: 16px;

  @include down-sm {
    font-size: 12px;
  }

  .rating:last-child {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}

.rating {
  display: flex;
  color: var(--bs-warning);

  i {
    margin-right: 5px;
    display: flex;

    &:last-child {
      margin-right: 0;
    }
  }
}

/* ----------------------
*   EXPERIENCE
---------------------------*/
.exp-sec {
  background-image: url('../../../public/images/bg-gradient-3.png');
  background-position: left center;
  background-repeat: no-repeat;
}


.ex-box {
  background: linear-gradient(156.67deg, rgba(112, 112, 114, 0.4) 0.07%, rgba(100, 97, 113, 0.148) 99.07%);
  box-shadow: 0px 7px 40px rgba(5, 34, 52, 0.06);
  border-radius: 16px;
  box-sizing: border-box;
  border: 2px solid transparent;
  padding: 25px;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  min-height: 260px;

  .ex-left {
    padding: 20px;
    border-radius: 8px;
    background: var(--px-theme);
    min-height: 200px;

    h4 {
      margin: 0 0 8px;
      font-weight: 700;
    }

    span {
      font-weight: 400;
      font-size: 14px;
      text-transform: uppercase;
      color: var(--px-white);
      opacity: 0.8;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      color: var(--px-white);
      text-transform: uppercase;
      margin: 0 0 15px;
    }

    label {
      padding: 8px 16px;
      border-radius: 30px;
      background: var(--px-white);
      color: var(--px-black);
      font-size: 12px;
      text-transform: uppercase;
      line-height: 1;
      font-weight: 600;
    }
  }

  .ex-right {
    h5 {
      font-weight: 700;
      font-size: 32px;

      @include down-sm {
        font-size: 28px;
      }
    }

    p {
      color: var(--px-white);
      opacity: 0.8;
    }
  }
}

/* ----------------------
*   Testimonials
---------------------------*/
.testi-sec {
    background-image: url('../../../public/images/bg-gradient-1_1.png');
    background-position: right center;
    background-repeat: no-repeat;
}

.testimonial-box {
  max-width: 780px;
  margin: 0 auto;
  text-align: center;

  .t-user {
    width: 130px;
    height: 130px;
    display: flex;
    margin: 0 auto 30px;
    border-radius: 50%;
    border: 8px solid rgba(255, 255, 255, 0.2);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .t-text {
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 18px;
    color: var(--px-white);
  }

  .t-person {
    h6 {
      font-weight: 500;
      font-size: 20px;
      text-transform: uppercase;
      margin: 0 0 8px;
    }

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: var(--px-white);
      opacity: 0.5;
    }
  }
}

/* ----------------------
*   Contact Us
---------------------------*/
.contactus-section {
  .contactus-title {
    h5 {
      font-weight: 500;
      font-size: 40px;

      @include down-sm {
        font-size: 32px;
      }
    }

    p {
      font-weight: 400;
      font-size: 17px;
      color: var(--px-white);
      max-width: 550px;
      opacity: 0.85;
    }
  }

  .contact-banner {
    @include up-md {
      margin-top: -200px;
    }
  }

  .contact-info {
    padding: 20px 0 40px;

    @include down-sm {
      padding: 20px 0 25px;
    }

    .contact-info-in {
      display: flex;
      flex-direction: column;
      padding-top: 20px;

      label {
        font-weight: 400;
        font-size: 12px;
        color: var(--px-white);
        margin: 0 0 2px;
        text-transform: uppercase;
        letter-spacing: 2px;
        opacity: 0.75;
      }

      a {
        color: var(--px-white);
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .contactus-box {
    border: 1px solid rgb(255 255 255 / 31%);
  }

  .contact-form {
    .form-label {
      color: var(--px-white);
      margin: 0 0 4px;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      opacity: 0.85;
    }

    .form-control {
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.15);
      color: var(--px-white);
      box-shadow: none;
      padding: 0.575rem 0.75rem;

      &::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
      }

      &:-moz-placeholder {
        color: rgba(255, 255, 255, 0.5);
        opacity: 1;
      }

      &::-moz-placeholder {
        color: rgba(255, 255, 255, 0.5);
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
      }

      &::-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
      }

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .contactus-form {
    border-top: 1px solid rgba(var(--px-theme-rgb), 0.2);
    background: #9f9f9f1a;
  }
}

/* ----------------------
*   Modal
---------------------------*/
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  overflow: hidden auto;
  background: rgba(0, 0, 0, 0.6);
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 30px 15px;
  box-sizing: border-box;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.mfp-content {
  cursor: auto;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-bg {
  background: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  cursor: zoom-out;
}

.slider-gap-24 {
  .slick-list {
    margin-left: -12px;
    margin-right: -12px;
  }

  .slick-track>* {
    padding: 0 12px;
  }
}

.slider-gap-50 {
  .slick-list {
    margin-left: -25px;
    margin-right: -25px;
  }

  .slick-track>* {
    padding: 0 25px;
  }

  @media (max-width: 991px) {
    .slick-list {
      margin-left: -12px;
      margin-right: -12px;
    }

    .slick-track>* {
      padding: 0 12px;
    }
  }
}

.slick-slider {
  .slick-track>*>* {
    display: flex;

    >* {
      width: 100%;
    }
  }
}

.disabled {
  opacity: 0.75;
  pointer-events: none;
}

/* ----------------------
*   Custom Curosor
---------------------------*/

.cs-cursor_lg,
.cs-cursor_sm {
  position: fixed;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  mix-blend-mode: difference;
  z-index: 9999;
}

.cs-cursor_lg {
  width: 40px;
  height: 40px;
  border: 1px solid transparent;
  left: 0;
  top: 0;
  pointer-events: none;
  transition: 0.15s;
}

.cs-cursor_sm {
  width: 10px;
  height: 10px;
  background-color: #fff;
  left: 15px;
  top: 15px;
  pointer-events: none;
  transition: all 0.1s ease;
}

@media screen and (max-width: 575px) {

  .cs-cursor_lg,
  .cs-cursor_sm {
    display: none;
  }
}

.index-module_type__E-SaG::after {
  position: relative;
  top: -5px;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes animo-x {
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateX(35px);
  }
}

@keyframes wave-animation {
  0% {
    transform: rotate(0.0deg)
  }

  10% {
    transform: rotate(14.0deg)
  }

  20% {
    transform: rotate(-8.0deg)
  }

  30% {
    transform: rotate(14.0deg)
  }

  40% {
    transform: rotate(-4.0deg)
  }

  50% {
    transform: rotate(10.0deg)
  }

  60% {
    transform: rotate(0.0deg)
  }

  100% {
    transform: rotate(0.0deg)
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #3749e9;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 7px 14px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 24px;
  z-index: 999;
  box-shadow: 0px 0 20px 3px #3749e97e;
}

.scroll-to-top.visible {
  opacity: 1;
}

