@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

$px-font: 'Sora', sans-serif !default;

// Theme Variable

$px-theme: #3749E9;
$px-theme-rgb: 7, 136, 255;
$px-white: #fff;
$px-black: #040c16;

$px-text: #707476;
$px-heading: #fff;

// Breakpoints
$px-media-xsm: 567px !default;
$px-media-sm: 767px !default;
$px-media-md: 991px !default;
$px-media-lg: 1200px !default;
